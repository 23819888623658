export {OpenLoginComponent} from './OpenLoginComponent';
export type {OpenLoginComponentDefinition} from './OpenLoginComponent';
export {ComponentDefinition} from './OpenLoginDefinition';
export type {SchemaType} from './OpenLoginDefinition';
export {OpenLoginPreviewComponent} from './OpenLoginPreviewComponent';

import {Registry} from '@backstage-components/base';
import {OpenLoginModule} from './OpenLoginModule';
import {ComponentDefinition} from './OpenLoginDefinition';
import {
  OpenLoginSuccessEvent,
  OpenLoginSuccessEventName,
} from './OpenLoginMachine';
Registry.register(ComponentDefinition, OpenLoginModule);

declare global {
  // Extends `#addEventListener` and `#removeEventListener` type definition to
  // include an overload for the `OpenLoginSuccessEventName` custom event
  interface HTMLElement {
    addEventListener(
      type: typeof OpenLoginSuccessEventName,
      listener: (this: HTMLElement, ev: OpenLoginSuccessEvent) => void,
      options?: boolean | AddEventListenerOptions
    ): void;
    removeEventListener(
      type: typeof OpenLoginSuccessEventName,
      listener: (this: HTMLElement, ev: OpenLoginSuccessEvent) => void,
      options?: boolean | EventListenerOptions
    ): void;
  }
}
