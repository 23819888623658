import {extractModuleIdentifiers, ModuleNode} from '@backstage-components/base';
import {FC} from 'react';
import {OpenLoginComponent} from './OpenLoginComponent';
import {SchemaType} from './OpenLoginDefinition';

export type OpenLoginProps = SchemaType;

export const OpenLoginPreviewComponent: FC<
  ModuleNode<'OpenLogin', OpenLoginProps>
> = (definition) => {
  const {component, config, props} = definition.module;
  return (
    <OpenLoginComponent
      component={component}
      config={config}
      {...extractModuleIdentifiers(definition.module)}
      props={props}
    />
  );
};
