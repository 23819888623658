import type {
  BroadcastFn,
  SchemaInstructionsHelper,
} from '@backstage-components/base';
import {assertEvent, assign, setup} from 'xstate';
import {ComponentDefinition} from './OpenLoginDefinition';

export type InstructionSchema = SchemaInstructionsHelper<
  typeof ComponentDefinition
>;

export const OpenLoginMachine = setup({
  types: {} as {
    context: Context;
    events: InstructionSchema;
    input: Pick<Context, 'broadcast' | 'showId'>;
  },
  actions: {
    broadcastFailure: ({context, event}) => {
      assertEvent(event, 'OpenLogin:failure');
      context.broadcast({
        type: 'OpenLogin:on-failure',
        meta: {
          showId: context.showId,
          reason: event.meta.reason,
        },
      });
    },
    broadcastSuccess: ({context, event}) => {
      assertEvent(event, 'OpenLogin:success');
      if (typeof window === 'object') {
        const e: OpenLoginSuccessEvent = new CustomEvent(
          OpenLoginSuccessEventName,
          {detail: {attendee: event.meta.attendee, showId: context.showId}}
        );
        document.body.dispatchEvent(e);
      }
    },
    updateAttendee: assign(({context, event}): SuccessContext => {
      assertEvent(event, 'OpenLogin:success');
      const attendee = event.meta.attendee;
      const nextContext: SuccessContext = {
        broadcast: context.broadcast,
        showId: context.showId,
        attendee,
      };
      return nextContext;
    }),
    updateReason: assign(({context, event}): FailureContext => {
      assertEvent(event, 'OpenLogin:failure');
      return {
        broadcast: context.broadcast,
        showId: context.showId,
        reason: event.meta.reason,
      };
    }),
  },
}).createMachine({
  id: 'OpenLogin',
  initial: 'idle',
  context: ({input}) => ({broadcast: input.broadcast, showId: input.showId}),
  states: {
    idle: {
      on: {'OpenLogin:verify': {target: 'pending'}},
    },
    pending: {
      on: {
        'OpenLogin:success': {
          target: 'success',
          actions: ['updateAttendee'],
        },
        'OpenLogin:failure': {
          target: 'failure',
          actions: ['updateReason'],
        },
      },
    },
    success: {
      entry: ['broadcastSuccess'],
      on: {'OpenLogin:verify': {target: 'pending'}},
    },
    failure: {
      entry: ['broadcastFailure'],
      on: {'OpenLogin:verify': {target: 'pending'}},
    },
  },
});

interface BaseContext {
  broadcast: BroadcastFn<(typeof ComponentDefinition)['instructions']>;
  showId: string;
}

interface FailureContext extends BaseContext {
  reason?: string;
}

interface SuccessContext extends BaseContext {
  attendee: Attendee;
}

type Context = BaseContext | FailureContext | SuccessContext;

interface Attendee {
  id: string;
  email: string | null;
  name: string;
  chatTokens: ChatToken[];
  tags: string[];
}

interface ChatToken {
  token: string;
}

export const OpenLoginSuccessEventName = 'OpenLogin:success';

export type OpenLoginSuccessEvent = CustomEvent<
  Pick<SuccessContext, 'attendee' | 'showId'>
>;
