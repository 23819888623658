import {Module, NextModule} from '@backstage-components/base';
import React from 'react';
import {
  OpenLoginComponent,
  OpenLoginComponentDefinition,
} from './OpenLoginComponent';
import {ComponentDefinition} from './OpenLoginDefinition';

type Kind = OpenLoginComponentDefinition['component'];

type Props = OpenLoginComponentDefinition['props'];

export const OpenLoginModule: Module<Kind, Props> = {
  isContent(value): value is OpenLoginComponentDefinition {
    return (
      value.component === ComponentDefinition.reactName &&
      value.cid === ComponentDefinition.id
    );
  },
  isLayout(value): value is OpenLoginComponentDefinition {
    return false;
  },
  tryRender(definition) {
    if (OpenLoginModule.isContent(definition)) {
      return React.createElement(OpenLoginComponent, definition);
    } else {
      return NextModule;
    }
  },
};
